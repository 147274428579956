import React from "react";
import Maps from "../static/Group 63.png";

const CardsMaps = React.memo(() => {
  return (
    <div className="mb-7 mt-7 lg:pl-16 lg:pr-16 p-4 cor-da-marca text-white flex flex-wrap justify-around">
      {/* Conteúdo textual */}
      <div className="lg:w-4/12 flex flex-col justify-center">
        <h1 className="mb-5 fontfamily">
          <span className="lg:text-2xl">Nossos painéis solares em</span>
          <br />
          <span className="text-amber-400 font-black lg:text-5xl text-3xl">
            todo o Amazonas
          </span>
        </h1>
        <h2 className="fontfamilyInter lg:text-2xl text-base">
          Confira os locais que já passamos e agora estão gerando energia de
          forma limpa e sustentável, tornando-se independentes energeticamente:{" "}
          <span className="text-amber-400 font-black">
            Manaus, Careiro da Várzea, Iranduba, Itacoatiara, Manacapuru, Novo
            Airão, Presidente Figueiredo e Rio Preto da Eva, entre outros.
          </span>
        </h2>
      </div>

      {/* Imagem com layout estático */}
      <div className="flex flex-col justify-center lg:w-4/12 md:w-full w-full p-4 cor-da-marca rounded-xl text-black">
        <img
          src={Maps}
          alt="Mapa detalhado mostrando a localização dos painéis solares em diversas cidades do Amazonas"
          className="w-full h-auto object-contain rounded-xl"
          loading="lazy"   // Carregamento preguiçoso
          width={600}       // Defina a largura estática
          height={400}      // Defina a altura estática
        />
      </div>
    </div>
  );
});

export default CardsMaps;
