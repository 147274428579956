import React from "react";
import NossaHistoria from "../static/image 22.png";

const HistoriaSobre = () => {
  return (
    <div className="flex p-4 flex-wrap justify-center">
      <img
        src={NossaHistoria}
        srcSet={`${NossaHistoria}?w=400 400w, ${NossaHistoria}?w=800 800w`}
        sizes="(max-width: 768px) 100vw, (min-width: 769px) 25vw"
        className="lg:w-1/4 w-full"
        alt="Ilustração representativa da história da empresa Ilumisol-AM, mostrando o início e crescimento desde a assinatura do contrato de franquia em 28/09/2018."
        loading="lazy"
      />
      <div className="font-bold p-4 lg:w-2/6 flex flex-col justify-center">
        <h2 className="cor-da-letra-03 lg:text-5xl text-4xl mb-3">Nossa história</h2>
        <div className="cor-da-letra text-2xl">
          <p className="mb-3 font-semibold">
            A empresa Ilumisol-AM foi criada em outubro de 2018 com a assinatura
            do contrato de Franquia em 28/09/2018.
          </p>
          <p>
            Desde essa data, começamos o planejamento para os próximos passos e
            já estávamos aptos para iniciar atividades de prospecção de vendas.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HistoriaSobre;
