import React from 'react';
import Banne from "../static/Como trabalhamos _.png";

const BanneSobre = () => {
  return (
    <div>
      <img
        src={Banne}
        className="w-full"
        alt="Banner ilustrativo mostrando como a empresa trabalha, destacando processos e serviços oferecidos."
        loading="lazy"
      />
    </div>
  );
};

export default BanneSobre;
