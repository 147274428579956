import React, { useCallback, useEffect, useState } from "react";
import logo from "../static/LOGOAPOEMATEST.svg"; // Caminho para o SVG
import menu from "../static/MenuTest.svg";
import { debounce } from "lodash";

const NavbarHome = React.memo(() => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 878);

  const toggleMenu = useCallback(() => {
    setIsMenuOpen(prev => !prev);
  }, []);

  const navItems = React.useMemo(() => ({
    "/home": "Início",
    "/Sobre": "Sobre",
    "/Projetos": "Projetos",
    "/Perguntas": "Perguntas frequentes",
    "/Usinas": "Serviços",
  }), []);

  useEffect(() => {
    const handleResize = debounce(() => {
      setIsMobile(window.innerWidth < 878);
    }, 200);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <nav className="bg-custom-gradient fixed top-0 w-full bg-[#002cc8] md:bg-inherit fontfamily z-40">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <a
          href="/home"
          className="relative flex items-center space-x-3 rtl:space-x-reverse m-2"
        >
          <img
            src={logo}
            className={`h-${isMobile ? '12' : '25'} z-40 mr-6`}
            alt="Logo"
            loading="lazy"
            width={isMobile ? 140 : 180}
            height={isMobile ? 140 : 180}
            decoding="async"
            fetchpriority="high"
          />
        </a>
        <button
          onClick={toggleMenu}
          type="button"
          className="inline-flex items-center p-2 w-14 h-14 hover:w-16 hover:h-16 justify-center rounded-3xl md:hidden focus:ring-gray-200 text-gray-400 focus:ring-gray-600"
          aria-controls="navbar-cta"
          aria-expanded={isMenuOpen ? "true" : "false"}
          aria-label={isMenuOpen ? "Fechar menu" : "Abrir menu"}
        >
          <img src={menu} alt="Menu" loading="lazy" fetchpriority="low" />
        </button>
        <div
          className={`md:flex ${isMenuOpen ? "block" : "hidden"} items-center justify-between w-full md:w-auto`}
          id="navbar-cta"
        >
          <ul className="flex flex-wrap justify-evenly w-full font-medium mt-4 rounded-xl bg-gray-50 md:space-x-8 md:flex-row md:mt-0 md:border-0 bg-transparent">
            {Object.entries(navItems).map(([href, label]) => (
              <li key={href} className="sm:p-3 md:p-2 lg:pr-4 lg:pl-4 w-45 md:border-none">
                <a
                  href={href}
                  className="block font-black p-5 py-2 px-3 md:p-0 text-gray-900 rounded text-white hover:text-black hover:bg-amber-400 md:hover:bg-transparent md:hover:text-amber-400"
                  aria-current={href === "/home" ? "page" : undefined}
                >
                  {label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
});

export default NavbarHome;
