import React, { useState, useEffect, useRef, useCallback, memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faPlay } from "@fortawesome/free-solid-svg-icons";

// Função de debounce para otimizar o redimensionamento da janela
const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const CarouselItem = memo(({ mediaItem, index, startIndex, playingVideoIndex, handlePlayPause }) => (
  <div
    key={index}
    className="h-96 lg:w-3/5 w-full tamanhoCard flex flex-col m-2 justify-center p-2 mb-4 transition-opacity duration-500 ease-in-out shadow-lg rounded-3xl relative"
  >
    {playingVideoIndex === startIndex + index ? (
      <iframe
        className="h-full w-full rounded-3xl object-cover"
        src={mediaItem.media_url}
        title={`Vídeo ${startIndex + index}`}
        aria-label={`Vídeo ${startIndex + index}`}
        allowFullScreen
        loading="lazy" // Carregamento preguiçoso
      ></iframe>
    ) : (
      <div
        className="h-full w-full rounded-3xl bg-black flex items-center justify-center"
        style={{ backgroundImage: `url(${mediaItem.thumbnail_url})`, backgroundSize: 'cover' }}
      >
        <button
          className="text-white text-3xl"
          onClick={() => handlePlayPause(startIndex + index)}
          aria-label={`Reproduzir vídeo ${startIndex + index}`}
        >
          <FontAwesomeIcon icon={faPlay} />
        </button>
      </div>
    )}
  </div>
));

const CarouselConteudos = memo(() => {
  const [media, setMedia] = useState([]);
  const [visibleImagesCount, setVisibleImagesCount] = useState(4);
  const [startIndex, setStartIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(null);
  const [playingVideoIndex, setPlayingVideoIndex] = useState(null);
  const cardsContainer = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  // Obtém o token de acesso do Instagram a partir das variáveis de ambiente
  const token = process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN;

  // Função para buscar mídias do Instagram
  const fetchInstagramMedia = useCallback(async () => {
    try {
      const response = await fetch(
        `https://graph.instagram.com/me?fields=id,username,media_count,followers_count,media{caption,id,is_shared_to_feed,media_type,media_url,permalink,thumbnail_url,timestamp}&access_token=${token}`
      );
      const data = await response.json();
      const videos = data.media.data.filter(item => item.media_type === 'VIDEO').slice(0, 8);
      setMedia(videos);
    } catch (error) {
      console.error('Error fetching Instagram media', error);
    }
  }, [token]);

  // Chama a função fetchInstagramMedia quando o componente é montado
  useEffect(() => {
    fetchInstagramMedia();
  }, [fetchInstagramMedia]);

  // Configura a lógica de redimensionamento da janela e define a quantidade de imagens visíveis
  useEffect(() => {
    const handleResize = debounce(() => {
      const width = window.innerWidth;
      setIsMobile(width < 768);
      setVisibleImagesCount(width < 710 ? 1 : width < 1162 ? 3 : 4);
    }, 300);

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleTouchStart = (event) => {
    setTouchStartX(event.touches[0].clientX);
  };

  const handleTouchEnd = (event) => {
    if (touchStartX === null || !cardsContainer.current) return;

    const touchEndX = event.changedTouches[0].clientX;
    const touchDiff = touchStartX - touchEndX;

    if (touchDiff > 50) nextSlide();
    else if (touchDiff < -50) prevSlide();

    setTouchStartX(null);
  };

  // Função para ir ao slide anterior
  const prevSlide = useCallback(() => {
    setPlayingVideoIndex(null);
    setStartIndex((prevIndex) => (prevIndex - 1 + media.length) % media.length);
  }, [media.length]);

  // Função para ir ao próximo slide
  const nextSlide = useCallback(() => {
    setPlayingVideoIndex(null);
    setStartIndex((prevIndex) => (prevIndex + 1) % media.length);
  }, [media.length]);

  // Obtém as mídias visíveis com base no índice inicial e na quantidade de imagens visíveis
  const getVisibleMedia = useCallback(() => {
    const end = startIndex + visibleImagesCount;
    return end <= media.length
      ? media.slice(startIndex, end)
      : [
          ...media.slice(startIndex),
          ...media.slice(0, end % media.length),
        ];
  }, [startIndex, visibleImagesCount, media]);

  const handlePlayPause = (index) => {
    setPlayingVideoIndex(index);
  };

  return (
    <div className="relative">
      <div
        className="flex flex-col fontfamily text-white"
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <div className="flex items-center justify-between">
          {!isMobile && (
            <button
              className="text-black text-2xl hide-on-mobile"
              onClick={prevSlide}
              aria-label="Anterior"
              tabIndex="0"
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
          )}
          <div ref={cardsContainer} className="flex justify-around w-full mx-2">
            {getVisibleMedia().map((mediaItem, index) => (
              <CarouselItem
                key={index}
                mediaItem={mediaItem}
                index={index}
                startIndex={startIndex}
                playingVideoIndex={playingVideoIndex}
                handlePlayPause={handlePlayPause}
              />
            ))}
          </div>
          {!isMobile && (
            <button
              className="text-black text-2xl hide-on-mobile"
              onClick={nextSlide}
              aria-label="Próximo"
              tabIndex="0"
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          )}
        </div>
        <div className="flex justify-center mt-10">
          {media.map((_, index) => (
            <span
              key={index}
              className={`indicator w-3 h-3 mx-1 rounded-full cursor-pointer ${
                startIndex <= index && index < startIndex + visibleImagesCount
                  ? "cor-da-marca-03"
                  : "bg-gray-200"
              }`}
              aria-label={`Ir para o slide ${index + 1}`}
              aria-current={startIndex === index ? "true" : "false"}
              onClick={() => {
                setPlayingVideoIndex(null);
                setStartIndex(index);
              }}
            ></span>
          ))}
        </div>
      </div>
    </div>
  );
});

export default CarouselConteudos;
