import React, { useState, useCallback } from "react";

import Residencial0 from "../static/Projetos/Rectangle13.png";
import Residencial01 from "../static/Projetos/Residencial01.png";
import Residencial02 from "../static/Projetos/Residencial02.png";
import Residencial03 from "../static/Projetos/Residencial03.png";
import Residencial04 from "../static/Projetos/Residencial04.png";
import Residencial05 from "../static/Projetos/Residencial05.png";
import Residencial06 from "../static/Projetos/Residencial06.png";
import Residencial07 from "../static/Projetos/Residencial07.png";
import Residencial08 from "../static/Projetos/Residencial08.png";
import Comercial from "../static/Projetos/Comercial.png";
import Comercial01 from "../static/Projetos/Comercial01.png";
import Comercial02 from "../static/Projetos/Comercial02.png";
import Comercial03 from "../static/Projetos/Comercial03.png";
import Comercial04 from "../static/Projetos/Comercial04.png";
import Comercial05 from "../static/Projetos/Comercial05.png";
import Comercial06 from "../static/Projetos/Comercial06.png";
import Comercial07 from "../static/Projetos/Comercial07.png";
import Comercial08 from "../static/Projetos/Comercial08.png";
import Comercial09 from "../static/Projetos/Comercial09.png";
import Comercial10 from "../static/Projetos/Comercial10.png";

const projetos = {
  Residencial: [
    {
      imgSrc: Residencial0,
      cliente: "Cliente residencial",
      economia: "R$ 36.696",
      text: "CLIENTE COMERCIAL",
      textli01: "Local: Manaus / AM ",
      textli02: "Status: Usina em Operação",
      textli03: "Potência nominal (kW): 21,0",
      textli04: " Potência (kWp): 26,015",
      textli05: "Geração mensal (kWh): 3.058",
      textli06: "Economia Ano: R$ R$ 36.696",
    },
    {
      imgSrc: Residencial07,
      cliente: "Cliente residencial",
      economia: "R$ 36.096,00",
      text: "CLIENTE COMERCIAL",
      textli01: "Local: Manaus / AM ",
      textli02: "Status: Usina em Operação",
      textli03: "Potência nominal (kW): 21,0",
      textli04: " Potência (kWp): 26,95",
      textli05: "Geração mensal (kWh): 3.008,0",
      textli06: "Economia Ano: R$ 705.347,52",
    },
    {
      imgSrc: Residencial06,
      cliente: "Cliente residencial",
      economia: "R$ 14.599,00",
      text: "CLIENTE COMERCIAL",
      textli01: "Local: Manaus / AM ",
      textli02: "Status: Usina em Operação",
      textli03: "Potência nominal (kW):9,10",
      textli04: " Potência (kWp): 10,90",
      textli05: "Geração mensal (kWh): 1.217",
      textli06: "Economia Ano: R$ 14.599,00",
    },
    {
      imgSrc: Residencial05,
      cliente: "Cliente residencial",
      economia: "R$ 15.936,00",
      text: "CLIENTE COMERCIAL",
      textli01: "Local: Manaus / AM",
      textli02: "Status: Usina em Operação",
      textli03: "Potência nominal (kW): 10,80",
      textli04: "Potência (kWp): 14,30",
      textli05: "Geração mensal (kWh): 1.600",
      textli06: "Economia Ano: R$ 15.936,00",
    },
    {
      imgSrc: Residencial04,
      cliente: "Cliente residencial",
      economia: "R$ 12.549,60",
      text: "CLIENTE COMERCIAL",
      textli01: "Local: Manaus / AM",
      textli02: "Status: Usina em Operação",
      textli03: "Potência nominal (kW): 10,50",
      textli04: "Potência (kWp): 11,55",
      textli05: "Geração mensal (kWh): 1.260",
      textli06: "Economia Ano: R$ 12.549,60",
    },
    {
      imgSrc: Residencial03,
      cliente: "Cliente residencial",
      economia: "R$ 9.003,84",
      text: "CLIENTE COMERCIAL",
      textli01: "Local: Manaus / AM",
      textli02: "Status: Usina em Operação",
      textli03: "Potência nominal (kW): 6,0",
      textli04: "Potência (kWp): 8,1",
      textli05: "Geração mensal (kWh): 904,0",
      textli06: "Economia Ano: R$ 9.003,84",
    },
    {
      imgSrc: Residencial02,
      cliente: "Cliente residencial",
      economia: "R$ 12.055,00",
      text: "CLIENTE COMERCIAL",
      textli01: "Local: Manaus / AM",
      textli02: "Status: Usina em Operação",
      textli03: "Potência nominal (kW): 7,5",
      textli04: "Potência (kWp): 9,0",
      textli05: "Geração mensal (kWh): 1.005",
      textli06: "Economia Ano: R$ 12.055,00",
    },
    {
      imgSrc: Residencial01,
      cliente: "Cliente residencial",
      economia: "R$ 12.374,00",
      text: "CLIENTE COMERCIAL",
      textli01: "Local: Manaus / AM",
      textli02: "Status: Usina em Operação",
      textli03: "Potência nominal (kW): 8,0",
      textli04: "Potência (kWp): 11,34",
      textli05: "Geração mensal (kWh): 1.031",
      textli06: "Economia Ano: R$ 12.374,00",
    },
    {
      imgSrc: Residencial08,
      cliente: "Cliente residencial",
      economia: "R$ 48.396,00",
      text: "CLIENTE COMERCIAL",
      textli01: "Local: Manaus / AM",
      textli02: "Status: Usina em Operação",
      textli03: "Potência nominal (kW): 30,0",
      textli04: "Potência (kWp): 36,04",
      textli05: "Geração mensal (kWh): 4.033,0",
      textli06: "Economia Ano: R$ 48.396,00",
    },
  ],
  Comercial: [
    {
      imgSrc: Comercial,
      cliente: "Cliente Comercial",
      economia: "R$ 10.800,00",
      text: "CLIENTE COMERCIAL",
      textli01: "Local: Manaus / AM",
      textli02: "Status: Usina em Operação",
      textli03: "Potência nominal (kW): 7,5",
      textli04: "Potência (kWp): 8,17",
      textli05: "Geração mensal (kWh): 900,0",
      textli06: "Economia Ano: R$ 10.800,00",
    },
    {
      imgSrc: Comercial01,
      cliente: "Cliente Comercial",
      economia: "R$ 150.216,00",
      text: "CLIENTE COMERCIAL",
      textli01: "Local: Manaus / AM",
      textli02: "Status: Usina em Operação",
      textli03: "Potência nominal (kW): 85,0",
      textli04: "Potência (kWp): 111.860,0",
      textli05: "Geração mensal (kWh): 12.522,0",
      textli06: "Economia Ano: R$ 150.216,00",
    },
    {
      imgSrc: Comercial02,
      cliente: "Cliente Comercial",
      economia: "R$ 197.676,00",
      text: "CLIENTE COMERCIAL",
      textli01: "Local: Manaus / AM",
      textli02: "Status: Usina em Operação",
      textli03: "Potência nominal (Kw): 115,5",
      textli04: "Potência (kWp): 147.150,00",
      textli05: "Geração mensal (kWh): 16.473,00",
      textli06: "Economia Ano: R$ 197.676,00",
    },
    {
      imgSrc: Comercial03,
      cliente: "Cliente Comercial",
      economia: "R$ 30.000",
      text: "CLIENTE COMERCIAL",
      textli01: "Local: Manaus / AM",
      textli02: "Status: Usina em Operação",
      textli03: "Potência nominal (Kw): 18,0",
      textli04: "Potência (kWp): 22,44",
      textli05: "Geração mensal (kWh): 2.500",
      textli06: "Economia Ano: R$ 30.000",
    },
    {
      imgSrc: Comercial04,
      cliente: "Cliente Comercial",
      economia: "R$ 38.880",
      text: "CLIENTE COMERCIAL",
      textli01: "Local: Manaus / AM",
      textli02: "Status: Usina em Operação",
      textli03: "Potência nominal (Kw): 25,0",
      textli04: "Potência (kWp): 29,15",
      textli05: "Geração mensal (kWh): 3.240",
      textli06: "Economia Ano: R$ 38.880",
    },
    {
      imgSrc: Comercial05,
      cliente: "Cliente Comercial",
      economia: "R$ 73.668",
      text: "CLIENTE COMERCIAL",
      textli01: "Local: Manaus / AM",
      textli02: "Status: Usina em Operação",
      textli03: "Potência nominal (Kw): 42,0",
      textli04: "Potência (kWp): 55,0",
      textli05: "Geração mensal (kWh): 6.139",
      textli06: "Economia Ano: R$ 73.668",
    },
    {
      imgSrc: Comercial06,
      cliente: "Cliente Comercial",
      economia: "R$ 19.989,72",
      text: "CLIENTE COMERCIAL",
      textli01: "Local: Manaus / AM",
      textli02: "Status: Usina em Operação",
      textli03: "Potência nominal (Kw): 13,50",
      textli04: "Potência (kWp): 17,10",
      textli05: "Geração mensal (kWh): 2.007",
      textli06: "Economia Ano: R$ 19.989,72",
    },
    {
      imgSrc: Comercial07,
      cliente: "Cliente Comercial",
      economia: "R$ 42.280,0",
      text: "CLIENTE COMERCIAL",
      textli01: "Local: Manaus / AM",
      textli02: "Status: Usina em Operação",
      textli03: "Potência nominal (Kw): 25,0",
      textli04: "Potência (kWp): 29,97",
      textli05: "Geração mensal (kWh): 3.523,0",
      textli06: "Economia Ano: R$ 42.280,0",
    },
    {
      imgSrc: Comercial08,
      cliente: "Cliente Comercial",
      economia: "R$ 59.092,68",
      text: "CLIENTE COMERCIAL",
      textli01: "Local: Manaus / AM",
      textli02: "Status: Usina em Operação",
      textli03: "Potência nominal (Kw): 50,0",
      textli04: "Potência (kWp): 50,40",
      textli05: "Geração mensal (kWh): 5.933",
      textli06: "Economia Ano: R$ 59.092,68",
    },
    {
      imgSrc: Comercial09,
      cliente: "Cliente Comercial",
      economia: "R$ 398.400,00",
      text: "CLIENTE COMERCIAL",
      textli01: "Local: Manaus / AM",
      textli02: "Status: Usina em Operação",
      textli03: "Potência nominal (Kw): 230,0",
      textli04: "Potência (kWp): 280,35",
      textli05: "Geração mensal (kWh): 33.200,0",
      textli06: "Economia Ano: R$ 398.400,00",
    },
    {
      imgSrc: Comercial10,
      cliente: "Cliente Comercial",
      economia: "R$  705.347,52",
      text: "CLIENTE COMERCIAL",
      textli01: "Local: Manaus / AM",
      textli02: "Status: Usina em Operação",
      textli03: "Potência nominal (Kw): 380,0",
      textli04: "Potência (kWp): 430.760,00",
      textli05: "Geração mensal (kWh): 58.776,96",
      textli06: "Economia Ano: R$  705.347,52",
    },
  ],
};

const CardsProjetos = () => {
  const [activeTab, setActiveTab] = useState("Residencial");
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleTabClick = useCallback((tab) => {
    setActiveTab(tab);
  }, []);

  const handleMouseEnter = useCallback((index) => {
    setHoveredIndex(index);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHoveredIndex(null);
  }, []);

  return (
    <div className="p-8">
      <h1 className="text-3xl text-blue-700 mb-2">Projetos</h1>
      <h2 className="text-5xl font-bold text-yellow-500 mb-8">{activeTab}</h2>
      <div className="flex cor-da-marca lg:w-72 space-x-4 mb-4 justify-center p-2 rounded-full">
        <button
          onClick={() => handleTabClick("Residencial")}
          className={`px-4 py-1 rounded-full ${
            activeTab === "Residencial"
              ? "bg-blue-600 text-white"
              : "bg-gray-200 text-blue-600"
          }`}
        >
          Residencial
        </button>
        <button
          onClick={() => handleTabClick("Comercial")}
          className={`px-4 py-1 rounded-full ${
            activeTab === "Comercial"
              ? "bg-blue-600 text-white"
              : "bg-gray-200 text-blue-600"
          }`}
        >
          Comercial
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {projetos[activeTab].map((projeto, index) => (
          <div key={index} className="rounded-lg">
            <div
              className="h-96 w-full flex flex-col justify-center mb-4 mt-5"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <div
                className={`transition-all duration-500 mt-5 ${
                  hoveredIndex === index ? "h-1/6" : "h-full"
                }`}
              >
                <img
                  src={projeto.imgSrc}
                  alt={`Imagem do projeto ${projeto.cliente}`}
                  className="rounded-3xl h-72 w-full object-cover"
                  loading="lazy"
                />
              </div>
              <div
                className={`transition-all duration-500 cor-da-marca-02 rounded-3xl ${
                  hoveredIndex === index ? "h-5/6" : "h-1/4"
                } mt-2`}
              >
                <div className="text-white text-lg flex justify-between items-center p-2">
                  <div>
                    <p className="text-sm">{projeto.cliente}</p>
                    <p className="font-bold">Economia Ano:</p>
                    <p className="text-green-500 text-xl font-bold">
                      {projeto.economia}
                    </p>
                  </div>
                </div>
                <div
                  className={`text-white transition-opacity duration-500 p-3 ${
                    hoveredIndex === index ? "opacity-100" : "opacity-0"
                  }`}
                >
                  <p>{projeto.text}</p>
                  <ul>
                    <li>{projeto.textli01}</li>
                    <li>{projeto.textli02}</li>
                    <li>{projeto.textli03}</li>
                    <li>{projeto.textli04}</li>
                    <li>{projeto.textli05}</li>
                    <li>{projeto.textli06}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardsProjetos;
