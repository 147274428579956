
// import React, {
//   useEffect,
//   useState,
//   useCallback,
//   useMemo,
//   Suspense,
//   lazy,
// } from "react";
// import ImgDoHome from "../static/Rectangle58.webp";
// import ImgDoHomeIsMobile from "../static/Rectangle81.webp";
// const FomsOrcamneto = lazy(() => import("./FomsOrcamneto"));

// const CardsBannes = React.memo(() => {
//   const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
//   const [currentTextIndex, setCurrentTextIndex] = useState(0);
//   const [isTransitioning, setIsTransitioning] = useState(false);

//   const texts = useMemo(
//     () => [
//       {
//         text0: "",
//         text: "GERADOS MENSALMENTE ",
//         highlight: ["637,37 kWh", " "],
//       },
//       { text0: "+DE", text: "CONCLUÍDAS", highlight: ["450 OBRAS", ""] },
//       { text0: "+DE", text: "MÓDULOS INSTALADOS", highlight: ["25 MIL", " "] },
//       {
//         text0: "+DE",
//         text: " EM ECONOMIA ANUAL AOS NOSSOS CLIENTES",
//         highlight: ["R$8 MILHÕES", " "],
//       },
//     ],
//     []
//   );

//   const setResponsiveness = useCallback(() => {
//     setIsMobile(window.innerWidth < 768);
//   }, []);

//   useEffect(() => {
//     setResponsiveness(); // Set initial state
//     window.addEventListener("resize", setResponsiveness);
//     return () => window.removeEventListener("resize", setResponsiveness);
//   }, [setResponsiveness]);

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setIsTransitioning(true);
//       setTimeout(() => {
//         setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
//         setIsTransitioning(false);
//       }, 300);
//     }, 5000);

//     return () => clearInterval(intervalId);
//   }, [texts.length]);

//   const renderText = useCallback(() => {
//     const { text0, text, highlight } = texts[currentTextIndex];
//     return (
//       <h1
//         className={`h1-car lg:text-3xl text-xl font-black transition-transform duration-300 fontfamily transform ${
//           isTransitioning
//             ? "translate-y-6 opacity-0"
//             : "-translate-y-0 opacity-100"
//         }`}
//       >
//         {highlight.length > 0 && (
//           <>
//             <p className="lg:text-white lg:text-3xl text-xl">{text0}</p>
//             <span className="cor-da-letra-03 lg:text-6xl text-3xl">
//               {highlight.join(" ")}
//             </span>
//           </>
//         )}
//         <p className="lg:text-white">{text}</p>
//       </h1>
//     );
//   }, [currentTextIndex, isTransitioning, texts]);

//   return (
//     <div className="w-full flex items-center">
//       <div className="w-full absolute">
//         <picture>
//           <source
//             srcSet={isMobile ? ImgDoHomeIsMobile : ImgDoHome}
//             media="(min-width: 768px)"
//           />
//           <img
//             loading="lazy"
//             className={`w-full ${
//               isMobile
//                 ? "height-Da-Imagens-home-isMobile"
//                 : "height-Da-Imagens-home"
//             }`}
//             srcSet={`${ImgDoHome} 1x, ${ImgDoHomeIsMobile} 2x`} // Exemplo de srcSet
//             sizes="(max-width: 768px) 100vw, 50vw"
//             src={isMobile ? ImgDoHomeIsMobile : ImgDoHome}
//             alt="Logo Apoema"
//           />
//         </picture>
//       </div>
//       <div
//         className={`flex w-full relative items-center justify-around flex-wrap ${
//           isMobile ? "mt-20 p-3" : "m-5 lg:pl-20 lg:pr-16 p-1 mt-14"
//         }`}
//       >
//         <div
//           className={`text-white flex ${
//             isMobile
//               ? "w-full mt-2 h-40 justify-center"
//               : "md:w-3/6 w-full md:h-4/5 py-12 items-end"
//           }`}
//         >
//           {renderText()}
//         </div>
//         <div
//           className={`relative form flex flex-col justify-center items-center ${
//             isMobile ? "w-full p-3 mr-2 ml-2" : "xl:w-2/6 2xl:w-2/6 p-3"
//           } rounded-xl overflow-hidden backdrop-blur-md drop-shadow-2xl`}
//         >
//           <h1 className="text-2xl text-white font-black relative z-10">
//             Solicite um{" "}
//             <span className="cor-da-letra-03 text-4xl lg:text-5xl">
//               orçamento!
//             </span>
//           </h1>
//           <div className="w-full mt-3 z-10">
//             <Suspense fallback={<div>Carregando...</div>}>
//               <FomsOrcamneto />
//             </Suspense>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// });

// export default CardsBannes;

import React, { useEffect, useState, useCallback, useMemo } from "react";
import ImgDoHome from "../static/Rectangle58.webp";
import ImgDoHomeIsMobile from "../static/Rectangle81.webp";
import FomsOrcamneto from "./FomsOrcamneto";

const CardsBannes = React.memo(() => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const texts = useMemo(
    () => [
      {
        text0: "",
        text: "GERADOS MENSALMENTE ",
        highlight: ["637,37 kWh", " "],
      },
      {
        text0: "+DE",
        text: "CONCLUÍDAS",
        highlight: ["450 OBRAS", ""],
      },
      {
        text0: "+DE",
        text: "MÓDULOS INSTALADOS",
        highlight: ["25 MIL", " "],
      },
      {
        text0: "+DE",
        text: " EM ECONOMIA ANUAL AOS NOSSOS CLIENTES",
        highlight: ["R$8 MILHÕES", " "],
      },
    ],
    []
  );

  const setResponsiveness = useCallback(() => {
    setIsMobile(window.innerWidth < 768);
  }, []);

  useEffect(() => {
    setResponsiveness(); // Set initial state
    window.addEventListener("resize", setResponsiveness);
    return () => window.removeEventListener("resize", setResponsiveness);
  }, [setResponsiveness]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setIsTransitioning(false);
      }, 300);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [texts.length]);

  const renderText = useCallback(() => {
    const { text0, text, highlight } = texts[currentTextIndex];
    return (
      <h1
        className={`h1-car lg:text-3xl text-xl font-black transition-transform duration-300 fontfamily transform ${
          isTransitioning
            ? "translate-y-6 opacity-0"
            : "-translate-y-0 opacity-100"
        }`}
      >
        {highlight.length > 0 && (
          <>
            <p className="lg:text-white lg:text-3xl text-xl">{text0}</p>
            <span className="cor-da-letra-03 lg:text-6xl text-3xl">
              {highlight.join(" ")}
            </span>
          </>
        )}
        <p className="lg:text-white">{text}</p>
      </h1>
    );
  }, [currentTextIndex, isTransitioning, texts]);

  return (
    <div className="w-full flex items-center">
      <div className="w-full absolute">
        <img
          loading="eager" // Carregar imediatamente para imagens visíveis
          className={`w-full ${
            isMobile ? "height-Da-Imagens-home-isMobile" : "height-Da-Imagens-home"
          }`}
          src={isMobile ? ImgDoHomeIsMobile : ImgDoHome}
          alt="Logo Apoema"
          width={isMobile ? 640 : 1280} // Adicione as dimensões para evitar CLS
          height={isMobile ? 360 : 720} // Adicione as dimensões para evitar CLS
        />
      </div>
      <div
        className={`flex w-full relative items-center justify-around flex-wrap ${
          isMobile ? "mt-20 p-3" : "m-5 lg:pl-20 lg:pr-16 p-1 mt-14"
        }`}
      >
        <div
          className={`text-white flex ${
            isMobile ? "w-full mt-2 h-40 justify-center" : "md:w-3/6 w-full md:h-4/5 py-12 items-end"
          }`}
        >
          {renderText()}
        </div>
        <div
          className={`relative form flex flex-col justify-center items-center ${
            isMobile ? "w-full p-3 mr-2 ml-2" : "xl:w-2/6 2xl:w-2/6 p-3"
          } rounded-xl overflow-hidden backdrop-blur-md drop-shadow-2xl`}
        >
          <h1 className="text-2xl text-white font-black relative z-10">
            Solicite um{" "}
            <span className="cor-da-letra-03 text-4xl lg:text-5xl">orçamento!</span>
          </h1>
          <div className="w-full mt-3 z-10">
            <FomsOrcamneto />
          </div>
        </div>
      </div>
    </div>
  );
});

export default CardsBannes;

