import React from "react";
import CarouselAtendimentoFacil from "./carousel/CarouselAtendimentoFacil";

const CardsAtendimentoFacil = () => {
  return (
    <div className="mb-7 mt-7 lg:pl-16 lg:pr-16 p-4">
      <h2 className="fontfamilyc mb-8">
        <span className="lg:text-5xl font-semibold text-3xl cor-da-letra">
          Solicite Um Orçamento
        </span>
        <br />
        <span className="text-amber-400 font-black lg:text-6xl text-4xl">
          fácil e rápido
        </span>
      </h2>

      <CarouselAtendimentoFacil />
    </div>
  );
};

export default CardsAtendimentoFacil;
