import React, { Suspense, lazy } from "react";

// Carregamento preguiçoso do CarouselClientes
const CarouselClientes = lazy(() => import("./carousel/CarouselClientes"));

const CardsClientes = () => {
  return (
    <div className="mb-7 mt-7 lg:pl-16 lg:pr-16 p-4">
      <h5 className="flex flex-col justify-center text-4xl mb-8 fontfamily font-black lg:w-2/6 w-94">
        <span className="cor-da-letra lg:text-5xl text-4xl">Nossos</span>
        <span className="cor-da-letra-03 lg:text-6xl text-5xl">Clientes</span>
      </h5>

      {/* Adicionando Suspense para o carregamento preguiçoso */}
      <Suspense fallback={<div>Loading...</div>}>
        <CarouselClientes />
      </Suspense>
    </div>
  );
};

export default React.memo(CardsClientes);
