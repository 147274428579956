import React, { useMemo } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Sustentabilidade from "../../static/Rectangle59.webp";
import Independencia from "../../static/Independencia.webp";
import Redução from "../../static/Redução.webp";
import Valorização from "../../static/Valorização.webp";

const CarouselPorQueEnergia = React.memo(() => {
  // Configurações do carrossel
  const settings = useMemo(() => ({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }), []);

  // Dados das imagens
  const images = useMemo(() => [
    {
      src: Sustentabilidade,
      alt: "Sustentabilidade ambiental",
      text01: "Sustentabilidade ambiental",
      text02:
        "Energia solar, através de Painéis Solares, é limpa e renovável, combatendo as emissões prejudiciais e contribuindo para a preservação do meio ambiente.",
    },
    {
      src: Independencia,
      alt: "Independência energética",
      text01: "Independência energética",
      text02:
        "Gerar sua própria eletricidade reduz a dependência das concessionárias e proporciona estabilidade financeira, além de autonomia energética.",
    },
    {
      src: Redução,
      alt: "Redução de custos a longo prazo",
      text01: "Redução de custos a longo prazo",
      text02:
        "Embora o investimento inicial em Painéis Solares seja significativo, a energia solar oferece economia substancial a longo prazo, aproveitando a abundância e gratuidade da luz solar.",
    },
    {
      src: Valorização,
      alt: "Valorização imobiliária",
      text01: "Valorização imobiliária",
      text02:
        "Propriedades com energia solar, utilizando Painéis Solares, têm valor de mercado mais alto e atraem compradores preocupados com eficiência energética, podendo receber incentivos fiscais.",
    },
  ], []);

  return (
    <section className="fontfamilyInter container mx-auto p-4 pb-8">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="p-4">
            <div className="rounded-lg shadow-lg hover:shadow-2xl p-4 h-full">
              <img
                loading="lazy"
                src={image.src}
                alt={image.alt}
                className="w-full h-60 bg-contain bg-center mb-4 rounded-lg object-cover"
               
              />
              <div className="mt-2 p-4 bg-blue-500 rounded-3xl flex flex-col justify-start text-white h-[240px] shadow-xl">
                <h1 className="text-lg font-black mb-2">{image.text01}</h1>
                <p className="text-xs">{image.text02}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
});

export default CarouselPorQueEnergia;
