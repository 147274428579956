import React, { Suspense, lazy } from "react";
import Img from "../static/Rectangle79.png";

// Carregamento assíncrono dos componentes
const CardsGarantiasNR10 = lazy(() => import("./CardsGarantiasNR10"));
const CardsGarantiasNR35 = lazy(() => import("./CardsGarantiasNR35"));
const CardsGarantiasNR5410 = lazy(() => import("./CardsGarantiasNR5410"));

const CardsGarantias = React.memo(() => {
  return (
    <div className="font-semibold text-gray-800 lg:text-2xl text-base mb-7 mt-7 lg:pl-16 lg:pr-16 p-4">
      <div className="flex justify-around flex-wrap items-center mb-5">
        <div className="lg:w-2/6 w-full text-blue-800">
          <h1 className="lg:text-5xl text-4xl mb-3">
            <span>Nossas</span>
            <br />
            <span className="cor-da-letra-03">Garantias</span>
          </h1>
          <p>
            O serviço será realizado por profissionais experientes em instalação e manutenção de sistemas de microgeração fotovoltaica, incluindo placas solares, buscando sempre atender às normas de segurança{" "}
            <span className="cor-da-letra-03">
              NR35, NR10 e instalações elétricas NBR5410.
            </span>
          </p>
        </div>
        <div className="w-full lg:w-3/6">
          <img
            src={Img}
            alt="Profissionais instalando um sistema de energia fotovoltaica"
            className="w-full h-auto"
            loading="lazy"
            srcSet={`${Img} 1x, ${Img} 2x`} // Adicione srcSet para fornecer diferentes resoluções
            sizes="(max-width: 768px) 100vw, 50vw" // Ajuste o tamanho conforme a largura da tela
            width="600" // Defina o tamanho da imagem para evitar CLS
            height="400" // Defina o tamanho da imagem para evitar CLS
          />
        </div>
      </div>
      <div className="lg:text-2xl text-base text-center lg:pl-20 lg:pr-20">
        <h2>
          <span className="text-primary-600">"Garantia de 01 ano</span> Contribua com o meio ambiente. Reduza a conta de energia!
        </h2>
      </div>
      {/* Use Suspense para carregamento assíncrono dos componentes */}
      <Suspense fallback={<div className="loading-placeholder">Carregando...</div>}>
        <CardsGarantiasNR10 />
        <CardsGarantiasNR35 />
        <CardsGarantiasNR5410 />
      </Suspense>
    </div>
  );
});

export default CardsGarantias;
