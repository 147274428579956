import React from "react";
import Apoema from "../static/apoemalogo.png";

const ApoemaSolar = () => {
  return (
    <div className="p-3 mt-5 mb-5 flex flex-col items-center">
      <img
        src={Apoema}
        className="lg:w-3/4"
        alt="Logotipo da Apoema Solar, que representa a nova marca criada em julho de 2023, com um design que simboliza visão e futuro."
        loading="lazy"
      />
      <h2 className="lg:w-3/4 w-full font-bold cor-da-letra-02 mt-8 lg:text-3xl text-center">
        Com isso, no mês de julho de 2023, foi criada a marca <span className="cor-da-letra-03">Apoema Solar</span>,
        onde "Apoema", uma palavra do Tupi-Guarani, significa <span className="cor-da-letra-03">“aquele que vê mais
        longe”</span>. Essa é uma referência clara para nossos clientes, que consideram que a
        energia solar não significa apenas economia na conta de energia, mas sim um investimento a longo prazo,
        tanto na questão financeira quanto na ambiental.
      </h2>
    </div>
  );
};

export default ApoemaSolar;
