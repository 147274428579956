import React, { Suspense, lazy } from "react";

// Carregamento preguiçoso do componente CarouselEstrutura
const CarouselEstrutura = lazy(() => import("./carousel/CarouselEstrutura"));

const Estruturas = () => {
  return (
    <div className="flex flex-col p-4 lg:pl-16 lg:pr-16 lg:w-full w-94">
      <div className="lg:mb-7 lg:mt-7">
        <h2 className="lg:text-6xl text-4xl mb-5">
          <span className="cor-da-letra-03 font-semibold">Nossa Estrutura</span>
        </h2>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <CarouselEstrutura />
      </Suspense>
    </div>
  );
};

export default Estruturas;
