import React, { useState, useEffect } from "react";
import whatsapp from "../static/Frame 149.png";

const WhatsAPP = () => {
  // Definindo o estado para controlar a visibilidade do componente
  const [isVisible, setIsVisible] = useState(false);

  // Função para redirecionar para o WhatsApp
  const redirectToWhatsApp = () => {
    // Substitua "seu-número" pelo número de telefone que você deseja usar no WhatsApp
    const phoneNumber = 9281906554;
    const message = "Olá! Estou interessado em seus serviços.";

    // Monta o link do WhatsApp com o número de telefone e a mensagem
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    // Redireciona para o WhatsApp
    window.open(whatsappLink, "_blank");
  };

  // Função para alternar a visibilidade do botão com intervalo de 10s e 2s
  const blinkButton = () => {
    setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 10000); // Botão fica visível por 10 segundos
  };

  useEffect(() => {
    const interval = setInterval(blinkButton, 12000);
    return () => clearInterval(interval);
  }, []); 
  return isVisible ? (
    <div className="fixed z-40 bottom-0 lg:p-10 p-5 right-3">
      <button
        onClick={redirectToWhatsApp}
        style={{ width: "170px", height: "70px", border: "none", background: "transparent" }}
      >
        <img src={whatsapp} alt="WhatsApp"/>
      </button>
    </div>
  ) : null;
};

export default WhatsAPP;

