import React, { Suspense, lazy } from "react";

// Lazy load the CarouselProjetosClientes component
const CarouselProjetosClientes = lazy(() => import("./carousel/CarouselProjetosClientes"));

const CardProjetosClientes = () => {
  return (
    <div className="cor-da-marca p-4 mt-7 mb-7">
      <div className="flex flex-col justify-center lg:justify-start w-full lg:pl-40 mb-7 lg:pr-16 p-4">
        <h1 className="font-bold fontfamily">
          <span className="text-white lg:text-4xl text-3xl">Sistemas de</span> <br />
          <span className="cor-da-letra-03 lg:text-5xl text-4xl">Energia solar</span>
        </h1>
        {/* Suspense to handle the loading state of the lazy-loaded component */}
        <Suspense fallback={<div>Loading...</div>}>
          <CarouselProjetosClientes />
        </Suspense>
      </div>
    </div>
  );
};

export default CardProjetosClientes;
