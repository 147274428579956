import React from "react";
import BanneSobre from "../components/cardsSobre/BanneSobre";
import HistoriaSobre from "../components/cardsSobre/HistoriaSobre";
import ApoemaSolar from "../components/cardsSobre/ApoemaSolar";
import QuintoCards from "../components/cardsSobre/QuintoCards";
import Estruturas from "../components/cardsSobre/Estruturas";
import LinhaDoTempo from "../components/cardsSobre/LinhaDoTempo";

const Sobre = () => {
  return (
    <body>
      <div className="lg:mt-0 md:mt-0 mt-20">
        <BanneSobre />
        <HistoriaSobre />
        <Estruturas />
        <LinhaDoTempo />
        <QuintoCards />
        <ApoemaSolar />
      </div>
    </body>
  );
};

export default Sobre;
