import React, { useState } from "react";

const CardsPerguntas = () => {
  const [activeQuestion, setActiveQuestion] = useState(null);

  const questions = [
    "Quais são os tipos de energia solar para imóveis?",
    "Quantos anos dura um sistema de energia solar?",
    "A energia solar funciona à noite?",
    "Dá para escolher conectar a energia solar apenas em aparelhos específicos, como ar-condicionado?",
  ];

  const answers = [
    "Atualmente, existem dois tipos: a energia solar térmica e a fotovoltaica. A energia solar térmica é o sistema de aquecimento solar, que serve para esquentar a água de chuveiros, torneiras, piscinas e o ambiente. Já a energia solar fotovoltaica é o sistema utilizado para gerar a própria energia elétrica do imóvel, tanto em locais que contam com o atendimento da concessionária da região.",
    "Um sistema de energia solar pode durar entre 25 a 30 anos, dependendo da qualidade dos componentes e da manutenção adequada.",
    "A energia solar não funciona diretamente à noite, pois os painéis solares precisam de luz solar para gerar eletricidade. No entanto, sistemas On Grid e Off Grid permitem o uso de energia solar à noite de maneiras diferentes: Sistemas On Grid conectam-se à rede elétrica durante o dia e utilizam créditos acumulados à noite. Sistemas Off Grid utilizam baterias para armazenar energia durante o dia, que é usada à noite.",
    "Sim, é possível direcionar a energia solar para alimentar aparelhos específicos, como um ar-condicionado. Isso pode ser feito através de um sistema de energia solar com um inversor inteligente ou através de dispositivos de monitoramento de energia. Um inversor inteligente pode ser programado para priorizar certos dispositivos ou circuitos elétricos. Além disso, dispositivos de monitoramento e controle de energia permitem o controle remoto dos aparelhos conectados, otimizando o uso da energia solar.",
  ];

  const toggleAnswer = (index) => {
    setActiveQuestion(activeQuestion === index ? null : index);
  };

  return (
    <div className="p-8 lg:pl-20 lg:pr-20">
      <h1 className="text-5xl font-bold text-blue-600 mb-4">PERGUNTAS</h1>
      <h2 className="text-5xl font-bold text-yellow-500 mb-8">FREQUENTES</h2>
      <div className="space-y-4 mb-8">
        {questions.map((question, index) => (
          <button
            key={index}
            onClick={() => toggleAnswer(index)}
            aria-expanded={activeQuestion === index}
            aria-controls={`answer-${index}`}
            className={`block w-full text-left p-4 rounded-lg transition-colors duration-300 ${
              activeQuestion === index
                ? "bg-blue-600 text-white"
                : "bg-gray-100 text-blue-600"
            }`}
          >
            <h3 className="text-xl font-semibold">{question}</h3>
          </button>
        ))}
      </div>
      {activeQuestion !== null && (
        <div
          id={`answer-${activeQuestion}`}
          className="bg-gray-100 p-6 rounded-lg shadow-md"
        >
          <p className="text-lg">{answers[activeQuestion]}</p>
        </div>
      )}
    </div>
  );
};

export default CardsPerguntas;
