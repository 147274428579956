import React from "react";
import Logo from "../static/LOGOAPOEMATEST.svg";
import Map from "../maps/Map";

const Footer = () => {
  return (
    <div className="w-full text-center">
      <footer className="bg-white cor-da-marca">
        <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
          <div className="md:flex md:justify-between">
            <div className="flex justify-center mb-6 md:mb-0">
              <a href="/" className="flex items-center">
                <img
                  src={Logo}
                  className="h-20 me-3"
                  alt="Logo da Apoema Solar"
                  loading="lazy"
                />
              </a>
            </div>
            <div className="w-3/12 hidden md:block">
              <Map id="desktop-map" />
            </div>

            <div>
              <h2 className="mb-6 text-sm font-semibold uppercase text-white">
                Contato
              </h2>
              <ul className="text-gray-400 font-medium">
                <li className="mb-4">
                  <a
                    href="https://www.whatsapp.com/catalog/559281906554/?app_absent=0"
                    className="hover:underline"
                    aria-label="Contato via WhatsApp"
                  >
                    +55 (92)9 8190-6554
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:adm@apoemasolar.com"
                    className="hover:underline"
                    aria-label="Contato via e-mail"
                  >
                    adm@apoemasolar.com
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <div className="mt-8 w-full h-32 block md:hidden">
                <Map id="mobile-map" />
              </div>
            </div>
          </div>

          <hr className="my-6 sm:mx-auto border-gray-700 lg:my-8" />
          <span className="text-sm sm:text-center text-gray-400">
            © 2024 Apoema Solar. Todos os direitos reservados.
          </span>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
