import React, { Suspense, lazy } from "react";

// Placeholder component for loading state
const Placeholder = () => (
  <div className="w-full h-96 bg-gray-200 rounded-3xl"></div>
);

// Lazy load CarouselFeedback component
const CarouselFeedback = lazy(() => import('./carousel/CarouselFeedback'));

const CardsFeedback = () => {
  return (
    <div className="cor-da-marca mb-7 mt-7 lg:pl-16 lg:pr-16 p-4">
      <h5 className="fontfamilyc mb-8">
        <span className="text-white lg:text-5xl font-semibold text-3xl">
          Feedbacks dos
        </span>
        <br />
        <span className="text-amber-400 font-black lg:text-6xl text-4xl">
          nossos clientes
        </span>
      </h5>
      <Suspense fallback={<Placeholder />}>
        <CarouselFeedback />
      </Suspense>
    </div>
  );
};

export default CardsFeedback;
