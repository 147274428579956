import React from "react";
import CarsPorqueEnergia from "../components/cardsHome/CarsPorqueEnergia";
import CardsSolucoes from "../components/cardsHome/CardsSolucoes";
import CardsBannes from "../components/cardsHome/CardsBannes";
import CardsQuemSomos from "../components/cardsHome/CardsQuemSomos";
import CardProjetosClientes from "../components/cardsHome/CardProjetosClientes";
import CardsClientes from "../components/cardsHome/CardsClientes";
import CardsMaps from "../components/cardsHome/CardsMaps";
import CardsAtendimentoFacil from "../components/cardsHome/CardsAtendimentoFacil";
import CardsFeedback from "../components/cardsHome/CardsFeedback";
import CardsNossasRedes from "../components/cardsHome/CardsNossasRedes";
import CardsGarantias from "../components/cardsHome/CardsGarantias";


const Home = () => {
  return (
    <div>
      <body>
        <CardsBannes />
        <CarsPorqueEnergia />
        <CardsSolucoes />
        <CardsQuemSomos />
        <CardProjetosClientes />
        <CardsClientes/>
        <CardsMaps />
        <CardsAtendimentoFacil />
        <CardsFeedback />
        <CardsNossasRedes />
        <CardsGarantias />
      </body>
    </div>
  );
};

export default Home;
