import React from "react";
import graphImage from "../static/Frame 206.png"; // Certifique-se de que a imagem está no diretório correto

const MercadoUsinas = () => {
  return (
    <div className="flex pt-8 pb-8 items-center justify-center h-auto bg-white">
      <div className="flex-wrap flex text-left lg:pr-2 ">
        <div className="lg:w-6/12 ">
          <img
            src={graphImage}
            alt="Gráfico de crescimento"
            className="lg:w-full h-auto"
          />
        </div>
        <div className="flex items-center font-bold lg:w-6/12 p-3">
          <p className="text-lg lg:text-3xl items-center  cor-da-letra ">
            Cada vez mais impactados pelo crescimento populacional e de consumo,
            governos, iniciativa privada e consumidores investem em meios de
            utilizar fontes renováveis de energia. O Brasil tem alcançado um{" "}
            <span className="text-yellow-500">
              crescimento inédito e rápido
            </span>{" "}
            na área de energia solar.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MercadoUsinas;
