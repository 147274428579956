import React, { useState } from "react";
import { Link } from "react-router-dom";
import Comercial from "../static/Rectangle13.webp";
import Residencial from "../static/residencial.webp";

const CardsSolucoes = React.memo(() => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const images = [
    {
      src: Comercial,
      alt: "Imagem representativa de solução comercial de energia solar",
      text01: "Comercial",
      text02:
        "A energia solar é convertida em eletricidade por meio do efeito fotovoltaico, que ocorre quando partículas de luz solar colidem com os átomos presentes no painel solar, gerando movimento dos elétrons e criando a corrente elétrica que chamamos de energia solar fotovoltaica.",
      route: "/Usinas",
    },
    {
      src: Residencial,
      alt: "Imagem representativa de solução residencial de energia solar",
      text01: "Residencial",
      text02:
        "A energia solar no Brasil tem um enorme potencial devido à alta incidência de luz solar durante o ano todo. Utilizar energia solar pode ser uma excelente alternativa para economizar na conta de luz e contribuir para a sustentabilidade.",
      route: "/Usinas",
    },
  ];

  return (
    <section className="mb-7 mt-7 lg:pl-16 lg:pr-16 p-4 pb-4 cor-da-marca flex flex-col fontfamily text-white">
      <div className="flex justify-center lg:justify-start w-full lg:pt-3 lg:pb-3">
        <h1 className="lg:text-5xl text-4xl font-bold">
          <span className="text-white">Economize até 95%</span> <br />
          <span className="cor-da-letra-03">com as nossas soluções</span>
        </h1>
      </div>
      <div className="flex mt-4 flex-wrap justify-around">
        {images.map((image, index) => (
          <div
            key={index}
            className="h-96 lg:w-2/6 w-full flex flex-col justify-center mb-4"
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <div
              className={`transition-all duration-500 ${
                hoveredIndex === index ? "h-1/6" : "h-5/6"
              }`}
            >
              <img
                src={image.src}
                alt={image.alt}
                loading="lazy"
                width="1000" // Ajuste conforme o layout
                height="600" // Ajuste conforme o layout
                className="h-full w-full rounded-3xl object-cover"
              />
            </div>
            <div
              className={`transition-all duration-500 cor-da-marca-02 rounded-3xl ${
                hoveredIndex === index ? "h-5/6" : "h-1/6"
              } mt-2`}
            >
              <div className="flex justify-between items-center p-1">
                <div className="pl-3">
                  <h1 className="font-bold text-lg">{image.text01}</h1>
                  <p
                    className={`text-zinc-300 truncate overflow-ellipsis transition-opacity duration-500 ${
                      hoveredIndex === index ? "opacity-0" : "opacity-100"
                    }`}
                  >
                    {image.text02.slice(0, 20)}...
                  </p>
                </div>
                <div className="pr-3">
                  <Link to={image.route}>
                    <button className="cor-da-letra bg-white rounded-3xl p-2 pr-4 pl-4 font-bold">
                      VER MAIS
                    </button>
                  </Link>
                </div>
              </div>
              <p
                className={`transition-opacity duration-500 p-3 ${
                  hoveredIndex === index ? "opacity-100" : "opacity-0"
                }`}
              >
                {image.text02}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
});

export default CardsSolucoes;
