import React, { useEffect, useRef } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Default Leaflet icon URLs
const defaultIcon = L.icon({
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
  iconSize: [25, 41], // Size of the icon
  iconAnchor: [12.5, 41], // Anchor point of the icon (half the horizontal size, full height)
  popupAnchor: [0, -41], // Popup anchor point
});

const Map = ({ id }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current) {
      return; // Skip if the map is already initialized
    }

    // Initialize the map and store the instance in mapRef.current
    const map = L.map(id).setView([-3.0730473, -60.005693], 13);
    mapRef.current = map;

    // Add the tile layer to the map (OpenStreetMap)
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);

    // List of objects with marker data
    const markersData = [
      {
        position: [-3.0730473, -60.005693],
        popupContent: "Apoema Solar - Energia Solar Manaus",
        icon: defaultIcon,
        googleMapsLink: "https://www.google.com/maps/search/?api=1&query=-3.0730473,-60.005693"
      },
    ];

    // Add markers dynamically to the map with custom icons
    markersData.forEach((marker) => {
      const leafletMarker = L.marker(marker.position, { icon: marker.icon })
        .addTo(map)
        .bindPopup(marker.popupContent);

      leafletMarker.on('click', () => {
        window.open(marker.googleMapsLink, '_blank');
      });
    });

    // Cleanup function to remove the map when the component unmounts
    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [id]); // depend on id to ensure a unique map instance for each container

  return <div id={id} className="w-full h-full"></div>;
};

export default Map;
