import React, { Suspense, lazy } from "react";

// Carregamento preguiçoso do componente CarouselLinhaDoTempo
const CarouselLinhaDoTempo = lazy(() => import("./carousel/CarouselLinhaDoTempo"));

const LinhaDoTempo = () => {
  return (
    <div className="flex flex-col p-4 lg:pl-16 lg:pr-16 lg:w-full w-94">
      <div className="lg:mt-7">
        <h2 className="lg:text-6xl text-4xl mb-5">
          <span className="cor-da-letra font-semibold">Linha do </span>
          <span className="cor-da-letra-03 font-semibold">tempo</span>
        </h2>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <CarouselLinhaDoTempo />
      </Suspense>
    </div>
  );
};

export default LinhaDoTempo;
