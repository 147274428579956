import React from "react";
import devinputLogo from "../static/inputMídia_Logo.png";

const FooterDevinput = () => {
  return (
    <div className="bg-black flex items-center justify-center py-1">
      <div className="flex items-center space-x-2">
        <p className="text-white">Site desenvolvido por:</p>
        <a href="http://devinput.com.br" target="_blank" rel="noopener noreferrer">
          <h2 className="text-white underline">devinput.com.br</h2>
        </a>
        <a href="http://devinput.com.br" target="_blank" rel="noopener noreferrer">
          <img src={devinputLogo} className="h-8" alt="Devinput Logo" />
        </a>
      </div>
    </div>
  );
};

export default FooterDevinput;
