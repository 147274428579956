import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "../pages/Home";
import WhatsAPP from "../components/iconWAPP/WhatsAPP";
import Footer from "../components/footer/Footer";
import Projetos from "../pages/Projetos";
import Usinas from "../pages/Usinas";
import Perguntas from "../pages/Perguntas";
import Sobre from "../pages/Sobre";
import NavbarHome from "../components/navbar/NavbarHome";
import FooterDevinput from "../components/footer/FooterDevinput";
import Test from "../pages/Test";

const Rotas = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <NavbarHome />
        <header>
          <WhatsAPP />
        </header>
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/Perguntas" element={<Perguntas />} />
            <Route path="/Projetos" element={<Projetos />} />
            <Route path="/Usinas" element={<Usinas />} />
            <Route path="/Sobre" element={<Sobre />} />
            <Route path="/Test" element={<Test />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </main>
        <footer>
          <Footer />
          <FooterDevinput />
        </footer>
      </div>
    </Router>
  );
};

export default Rotas;







