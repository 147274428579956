import React, { useEffect, useState, useMemo } from "react";
import ImgResidencia from "../static/Rectangle 26.png";
import ImgInversor from "../static/Rectangle 72.png";
import ImgComercialT from "../static/Rectangle 76.png";
import CarouselPorQueEnergia from "../cardsHome/carousel/CarouselPorQueEnergia";
import MercadoUsinas from "./MercadoUsinas";

const UsinasSolares = () => {
  const [activeTab, setActiveTab] = useState("Residencial");
  const [isMobile, setIsMobile] = useState(false);

  const Usinas = useMemo(() => ({
    Residencial: [
      {
        L_imgSrc: ImgResidencia,
        L_title: "Como",
        L_title02: "funciona?",
        L_description:
          "Painéis solares (módulos fotovoltaicos): São os dispositivos responsáveis por capturar a luz solar e convertê-la em eletricidade. Eles são compostos por células fotovoltaicas que geram uma corrente elétrica quando expostas à luz solar. A energia fotovoltaica instalação é crucial para que o sistema funcione corretamente, garantindo a eficiência na conversão de energia solar em energia elétrica fotovoltaica. Além disso, a energia solar em empresa tem se tornado uma prática cada vez mais comum, proporcionando economia e sustentabilidade.",
      },
      {
        R_imgSrc: ImgInversor,
        R_title: "Sistemas de",
        R_title02: "energia do sol",
        R_description:
          "Inversores solares: Os inversores convertem a eletricidade gerada pelos painéis solares de corrente contínua (CC) para corrente alternada (CA), que é a forma de eletricidade usada na maioria dos equipamentos elétricos comerciais.",
      },
      {
        L_imgSrc: ImgComercialT,
        L_title: "Todo projeto é ",
        L_title02: "único",
        L_description:
          "Esses sistemas podem ser dimensionados de acordo com as necessidades energéticas individuais de uma residência e podem incluir sistemas de armazenamento de energia, como baterias, para fornecer energia durante a noite ou em dias nublados.",
      },
      {
        R_imgSrc: ImgInversor,
        R_title: "Depois de pegar ",
        R_title02: "energia do sol",
        R_description:
          "Inversores solares: Os inversores convertem a eletricidade gerada pelos painéis solares de corrente contínua (CC) para corrente alternada (CA), que é a forma de eletricidade usada na maioria dos equipamentos elétricos comerciais.",
      },
    ],
    Comercial: [
      {
        L_imgSrc: ImgComercialT,
        L_title: "Painéis solares",
        L_title02: "(módulos fotovoltaicos)",
        L_description:
          "São os dispositivos responsáveis por capturar a luz solar e convertê-la em eletricidade. Eles são compostos por células fotovoltaicas que geram uma corrente elétrica quando expostas à luz solar.",
      },
      {
        R_imgSrc: ImgInversor,
        R_title: "Depôs de pegar ",
        R_title02: "energia do sol",
        R_description:
          "Os inversores convertem a eletricidade gerada pelos painéis solares de corrente contínua (CC) para corrente alternada (CA), que é a forma de eletricidade usada na maioria dos equipamentos elétricos comerciais.",
      },
      {
        L_imgSrc: ImgComercialT,
        L_title: "Estrutura de",
        L_title02: " montagem",
        L_description:
          " Os painéis solares são montados em estruturas fixas ou ajustáveis, geralmente instaladas em telhados ou em áreas abertas onde recebem a máxima exposição solar.",
      },
      {
        R_imgSrc: ImgInversor,
        R_title: "Todo projeto é ",
        R_title02: "único",
        R_description:
          "Esses sistemas podem ser dimensionados de acordo com as necessidades energéticas individuais de uma residência e podem incluir sistemas de armazenamento de energia, como baterias, para fornecer energia durante a noite ou em dias nublados.",
      },
    ],
  }), []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="bg-[#002cc8] lg:bg-white">
      <div className="flex lg:justify-between justify-center items-center cor-da-marca shadow-md text-white">
        <div className="lg:pl-20 lg:mt-0 mt-5">
          <h1 className="text-4xl mb-2">Usinas solares</h1>
          <h2 className="text-6xl font-bold text-yellow-500 mb-8">{activeTab}</h2>
          <div className="p-3 flex justify-around space-x-4 mb-8 bg-blue-900 rounded-full">
            <button
              onClick={() => setActiveTab("Residencial")}
              className={`px-4 py-2 rounded-full ${
                activeTab === "Residencial"
                  ? "bg-yellow-500 text-blue-700"
                  : "bg-white text-blue-700"
              }`}
            >
              Residencial
            </button>
            <button
              onClick={() => setActiveTab("Comercial")}
              className={`px-4 py-2 rounded-full ${
                activeTab === "Comercial"
                  ? "bg-yellow-500 text-blue-700"
                  : "bg-white text-blue-700"
              }`}
            >
              Comercial
            </button>
          </div>
        </div>

        {!isMobile && (
          <div>
            <img
              src={ImgResidencia}
              alt="Imagem representativa de usinas solares residenciais."
              className="pl-4 w-full h-full"
              loading="lazy"
            />
          </div>
        )}
      </div>

      <div className="container mx-auto mt-8 p-3">
        {Usinas[activeTab].map((item, index) => (
          <div key={index}>
            <div
              className={`cor-da-marca rounded-3xl w-full flex flex-col lg:flex-row text-white items-center ${
                index % 2 !== 0 ? "lg:flex-row-reverse" : ""
              }`}
            >
              {item.L_imgSrc && (
                <div className="w-full">
                  <img
                    src={item.L_imgSrc}
                    alt={item.L_title}
                    className="w-full h-full lg:pr-4"
                    loading="lazy"
                  />
                </div>
              )}
              <div className="h-full flex items-center lg:pr-4 lg:w-4/5">
                <div className="lg:pt-0 pt-1">
                  <h3 className="text-3xl font-bold">{item.L_title}</h3>
                  <h3 className="text-3xl font-bold cor-da-letra-03">{item.L_title02}</h3>
                  <h1 className="text-lg">{item.L_description}</h1>
                </div>
              </div>
            </div>

            <div
              className={`mb-16 cor-da-marca rounded-3xl w-full flex flex-col lg:flex-row text-white items-center ${
                index % 2 !== 0 ? "lg:flex-row-reverse" : ""
              }`}
            >
              {item.R_imgSrc && (
                <div className="w-full">
                  <img
                    src={item.R_imgSrc}
                    alt={item.R_title}
                    className="w-full h-full lg:pl-4 lg:p-0"
                    loading="lazy"
                  />
                </div>
              )}
              <div className="h-full flex items-center lg:pl-4 lg:w-4/5">
                <div className="lg:pt-0 pt-2">
                  <h1 className="text-3xl font-bold">{item.R_title}</h1>
                  <h1 className="text-3xl font-bold cor-da-letra-03">{item.R_title02}</h1>
                  <p className="text-lg">{item.R_description}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="cor-da-marca flex flex-col fontfamily">
        <div className="lg:w-3/5 w-94 lg:pl-40 lg:pr-16 p-3">
          <h1 className="lg:text-6xl text-4xl mb-5">
            <span className="text-white font-black">Por que aderir à energia </span>
            <span className="cor-da-letra-03 font-black">solar residencial </span>
          </h1>
        </div>
        <CarouselPorQueEnergia />
        <MercadoUsinas />
      </div>

      <div className="cor-da-marca flex text-white text-center">
        <div className="w-2/6 lg:p-2 p-1">
          <h2 className="font-bold lg:mb-4 mb-1 cor-da-letra-03 lg:text-8xl text-xl">230%</h2>
          <p className="text-xs lg:font-bold lg:text-base">crescimento anual no Brasil em 2020</p>
        </div>
        <div className="w-2/6 lg:p-2 p-1">
          <h2 className="lg:mb-4 font-bold mb-1 cor-da-letra-03 lg:text-8xl text-xl">18MIL</h2>
          <p className="text-xs lg:font-bold lg:text-base">usinas solares instaladas em território nacional.</p>
        </div>
        <div className="w-2/6 lg:p-2 p-1">
          <h2 className="lg:mb-4 font-bold mb-1 cor-da-letra-03 lg:text-8xl text-xl">6GW</h2>
          <p className="text-xs lg:font-bold lg:text-base">potência instalada em energia solar em 2020</p>
        </div>
      </div>
    </div>
  );
};

export default UsinasSolares;
