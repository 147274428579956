import React from "react";
import Logo from "../static/apoema 2 1.png";

const CardsQuemSomos = React.memo(() => {
  return (
    <section className="mb-7 mt-7 lg:pl-16 lg:pr-16 p-4">
      <h5 className="lg:w-full w-94 lg:text-6xl text-4xl mb-8 font-bold">
        <span className="cor-da-letra mr-4">Quem</span>
        <span className="cor-da-letra-03">Somos?</span>
      </h5>

      <div className="flex justify-center w-full">
        <img
          className="lg:w-7/12 w-full h-auto p-2"
          src={Logo}
          alt="Logo da Apoema"
          loading="lazy"
          width="800"  // Define a largura da imagem
          height="500" // Define a altura da imagem
          // Consider adding a blur-up technique or low-quality image placeholder (LQIP)
        />
      </div>

      <div className="mt-7 lg:w-full w-94">
        <h5 className="mb-3 cor-da-letra font-bold">
          Desde o início, nossa jornada foi marcada por um comprometimento
          incansável em alcançar novos patamares e superar desafios. Em novembro
          de 2018, concretizamos nosso primeiro marco ao vender o primeiro Kit
          para nossa cliente inaugural, Ana Claúdia Cavalcante Lucena. Esse foi
          apenas o começo de uma trajetória repleta de conquistas e crescimento
          constante.
        </h5>
        <a
          href="/Sobre"
          className="bg-amber-400 rounded-3xl hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium lg:text-base text-sm font-black px-5 py-2 text-center"
          aria-label="Saiba mais sobre nós"
        >
          Tudo sobre nós
        </a>
      </div>
    </section>
  );
});

export default CardsQuemSomos;
