import React from "react";
import CardsPerguntas from "../components/cardDePerguntas/CardsPerguntas";

const Perguntas = () => {
  return (
    <body>
      <div className="lg:mt-18 mt-16">
        <CardsPerguntas />
      </div>
    </body>
  );
};

export default Perguntas;
