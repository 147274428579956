// Codigo ja otimizador
import React, { useState, useCallback } from "react";

const FormularioOrcamento = () => {
  const [formData, setFormData] = useState({
    nome: "",
    telefone: "",
    email: "",
    consumoKWH: "",
  });

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    // Validar dados antes de prosseguir
    const { nome, telefone, email, consumoKWH } = formData;
    if (!nome || !telefone || !email || !consumoKWH) {
      console.error("Preencha todos os campos obrigatórios");
      return;
    }

    const dataAtual = new Date().toLocaleString("pt-BR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    const [dataFormatada, horarioFormatado] = dataAtual.split(" ");

    const formDataComDataHora = {
      ...formData,
      data: dataFormatada,
      horario: horarioFormatado,
    };

    const mensagem = `Olá, meu nome é ${nome}. Meu telefone é ${telefone}. Meu email é ${email}. O consumo de KWH é ${consumoKWH} KWH. Gostaria de solicitar um orçamento.`;
    const linkWhatsapp = `https://api.whatsapp.com/send?phone=559281906554&text=${encodeURIComponent(
      mensagem
    )}`;
    window.open(linkWhatsapp);

    try {
      const response = await fetch(
        `https://api.sheetmonkey.io/form/sKHRs3AGzyDpyC6KZgQ2VQ`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formDataComDataHora),
        }
      );

      if (response.ok) {
        console.log("Dados enviados com sucesso!");
        // Limpar o formulário após o envio
        setFormData({
          nome: "",
          telefone: "",
          email: "",
          consumoKWH: "",
        });
      } else {
        console.error("Erro ao enviar os dados para a API");
      }
    } catch (error) {
      console.error("Erro ao enviar os dados para a API", error);
    }
  }, [formData]);

  return (
    <form
      className="w-full max-w-lg mx-auto pr-3 pl-3 rounded-lg"
      onSubmit={handleSubmit}
    >
      <div>
        <label
          htmlFor="nome"
          className="font-black text-white drop-shadow-lg"
        >
          Seu nome:
        </label>
        <input
          type="text"
          name="nome"
          value={formData.nome}
          onChange={handleChange}
          className="rounded-lg m-2 border block w-full text-sm p-2.5 bg-blue-700 text-white"
          placeholder="Digite aqui"
          required
        />
      </div>
      <label
        htmlFor="telefone"
        className="font-black text-white drop-shadow-lg"
      >
        Telefone para contato:
      </label>
      <input
        type="tel"
        name="telefone"
        value={formData.telefone}
        onChange={handleChange}
        className="rounded-lg m-2 border block w-full text-sm p-2.5 bg-blue-700 text-white"
        placeholder="Telefone"
        required
      />
      <label
        htmlFor="email"
        className="font-black text-white drop-shadow-lg"
      >
        Email:
      </label>
      <input
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        className="rounded-lg m-2 border block w-full text-sm p-2.5 bg-blue-700 text-white"
        placeholder="Email"
        required
      />
      <label
        htmlFor="consumoKWH"
        className="font-black text-white drop-shadow-lg"
      >
        Valor da sua última conta de energia:
      </label>
      <input
        type="number"
        name="consumoKWH"
        value={formData.consumoKWH}
        onChange={handleChange}
        className="rounded-lg m-2 border block w-full text-sm p-2.5 bg-blue-700 text-white"
        placeholder="Consumo em KWH"
        required
      />
      <button
        type="submit"
        className="font-bold bg-amber-400 rounded-lg m-2 mt-4 border block w-full text-sm p-2.5 text-black"
      >
        Solicitar orçamento
      </button>
    </form>
  );
};

export default React.memo(FormularioOrcamento);
