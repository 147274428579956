import React, { useCallback } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Solucoes from "../../static/iconsAtendimentos/Rectangle 59.png";
import Disponibilidade from "../../static/iconsAtendimentos/Rectangle 59-1.png";
import Resposta from "../../static/iconsAtendimentos/Rectangle 59-2.png";
import Profissionais from "../../static/iconsAtendimentos/Rectangle 59-3.png";

const images = [
  {
    src: Solucoes,
    text01: "Soluções eficientes",
    text02: "Buscamos resolver os problemas dos clientes de forma rápida e eficaz, incluindo a integração de soluções sustentáveis como Painéis Solares, para que você possa voltar às suas atividades sem interrupções.",
  },
  {
    src: Disponibilidade,
    text01: "Disponibilidade total",
    text02: "Não importa a hora do dia ou da noite, estamos sempre disponíveis para atender às suas necessidades, seja com placa solar ou não.",
  },
  {
    src: Resposta,
    text01: "Resposta instantânea",
    text02: "Garantimos a qualidade e perfeição da execução dos serviços fornecidos ou executados, incluindo a instalação de Painéis Solares, respondendo, na forma da lei, por quaisquer defeitos decorrentes, pelo prazo de 12 meses.",
  },
  {
    src: Profissionais,
    text01: "Profissionais qualificados",
    text02: "Contamos com uma equipe experiente e qualificada, pronta para oferecer suporte técnico e assistência personalizada para instalação de Painéis Solares.",
  },
];

const CarouselItem = React.memo(({ image }) => (
  <div className="flex flex-col items-center p-4 bg-white shadow-lg rounded-xl">
    <img
      src={image.src}
      alt={`Slide - ${image.text01}`}
      className="w-full h-60 object-cover rounded-3xl"
      loading="lazy"   // Carregamento preguiçoso
      width={400}       // Defina a largura estática
      height={240}      // Defina a altura estática
    />
    <div className="mt-2 p-4 bg-blue-500 rounded-3xl flex flex-col justify-start text-white h-[240px]">
      <h1 className="text-lg font-black mb-2">{image.text01}</h1>
      <p className="text-sm">{image.text02}</p>
    </div>
  </div>
));

const CarouselAtendimentoFacil = () => {
  const settings = useCallback(() => ({
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }), []);

  return (
    <div className="container mx-auto p-2">
      <Slider {...settings()}>
        {images.map((image, index) => (
          <CarouselItem key={index} image={image} />
        ))}
      </Slider>
    </div>
  );
};

export default CarouselAtendimentoFacil;
