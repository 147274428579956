// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Roboto:wght@400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: "Roboto", sans-serif;
}
.form {
  border: 1px solid transparent;
  border: 1px solid #002cc8;
  border-radius: 25px;
}

.cor-da-marca {
  background-color: #002cc8;
}
.cor-da-marca-02 {
  background-color: #3d68ff;
}
.cor-da-marca-03 {
  background-color: #ffb926;
}
.cor-da-letra {
  color: #002cc8;
}
.cor-da-letra-02 {
  color: #3d68ff;
}
.cor-da-letra-03 {
  color: #ffb926;
}


.tamanhoImg {
  height: 490px;
}
.tamanhoCard {
  height: 520px;
}
.h1-car span {
  color: #ffb926;
}

.height-Da-Imagens-home {
  height: 560px;
  flex-wrap: wrap;
}
.height-Da-Imagens-home-isMobile {
  height: 800px;
  flex-wrap: wrap;
}

.cor-funto {
  background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQA9dy0pdsM-Hd2BwzL38vMZ8nIeABY_ewD2PPV3LRreKoDekXnpZvOnvk0MVD5eXlAXQ&usqp=CAU");
  padding: 5px;
  border-radius: 20px;
  background-size: cover;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,iCAAiC;AACnC;AACA;EACE,6BAA6B;EAC7B,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;;;AAGA;EACE,aAAa;AACf;AACA;EACE,aAAa;AACf;AACA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;AACA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,6JAA6J;EAC7J,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;AACxB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Roboto:wght@400;700&display=swap\");\n\n* {\n  font-family: \"Roboto\", sans-serif;\n}\n.form {\n  border: 1px solid transparent;\n  border: 1px solid #002cc8;\n  border-radius: 25px;\n}\n\n.cor-da-marca {\n  background-color: #002cc8;\n}\n.cor-da-marca-02 {\n  background-color: #3d68ff;\n}\n.cor-da-marca-03 {\n  background-color: #ffb926;\n}\n.cor-da-letra {\n  color: #002cc8;\n}\n.cor-da-letra-02 {\n  color: #3d68ff;\n}\n.cor-da-letra-03 {\n  color: #ffb926;\n}\n\n\n.tamanhoImg {\n  height: 490px;\n}\n.tamanhoCard {\n  height: 520px;\n}\n.h1-car span {\n  color: #ffb926;\n}\n\n.height-Da-Imagens-home {\n  height: 560px;\n  flex-wrap: wrap;\n}\n.height-Da-Imagens-home-isMobile {\n  height: 800px;\n  flex-wrap: wrap;\n}\n\n.cor-funto {\n  background-image: url(\"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQA9dy0pdsM-Hd2BwzL38vMZ8nIeABY_ewD2PPV3LRreKoDekXnpZvOnvk0MVD5eXlAXQ&usqp=CAU\");\n  padding: 5px;\n  border-radius: 20px;\n  background-size: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
