import React, { useEffect } from "react";
import CardsProjetos from "../components/cardDEProjetos/CardsProjetos";


const Projetos = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <body>
      <div className="lg:mt-18 mt-16">
        <CardsProjetos />
      </div>
    </body>
  );
};

export default Projetos;
