import React from "react";
import CarouselPorQueEnergia from "./carousel/CarouselPorQueEnergia";

const CarsPorqueEnergia = () => {
  return (
    <div className="lg:mt-10 md:mt-10 mt-20 flex flex-col p-4 lg:pl-16 lg:pr-16 lg:w-full w-94">
      <div className="lg:mb-7 lg:mt-7">
        <h1 className="lg:text-6xl text-4xl mb-5">
          <span className="cor-da-letra font-medium">Energia </span>
          <br />
          <span className="cor-da-letra-03 font-semibold">Solar Manaus</span>
        </h1>
      </div>
      <CarouselPorQueEnergia />
    </div>
  );
};

export default React.memo(CarsPorqueEnergia);
