import React from "react";
import Img from "../static/Group 64.png";

const QuintoCards = () => {
  return (
    <div className="flex test01 flex-wrap items-center justify-center w-full p-4">
      <h2 className="cor-da-letra-02 font-bold lg:text-3xl lg:w-4/12 w-full">
        <span className="cor-da-letra-03">Em junho de 2023</span>, devido à proximidade do término de contrato de
        franquia, houve a decisão de criar uma nova marca própria e caminhar junto
        à Ilumisol, apenas como agente autorizado.
      </h2>
      <img
        className="lg:w-4/12 w-8/12"
        src={Img}
        alt="Imagem ilustrativa que representa a transição da marca em junho de 2023, destacando a decisão de criar uma nova marca própria enquanto continua como agente autorizado da Ilumisol."
        loading="lazy"
      />
    </div>
  );
};

export default QuintoCards;
