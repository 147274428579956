import React, { useEffect } from "react";
import UsinasSolares from "../components/cardsUsinas/UsinasSolares";

const Usinas = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="lg:mt-0 md:mt-0 mt-20">
        <UsinasSolares />
      </div>
    </div>
  );
};

export default Usinas;
